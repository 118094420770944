import React, { useEffect, useState } from 'react';

import { Select, Tooltip, Switch } from '@armis/armis-ui-library';
import { InputLabel, MenuItem } from '@mui/material';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
    LOG_OUT,
    NO_RESULT_REGIONS,
    MY_PROFILE as PROFILE,
    REGIONS_LABEL
} from 'src/constants/LabelText';
import {
    DASHLETS_ASQ,
    LOGIN,
    MY_PROFILE,
    SETTINGS
} from 'src/constants/RouteConstants';
import { displayErrorMessage } from 'src/helpers/utility';
import { logout } from 'src/services/api.service';
import {
    selectRegions,
    selectSelectedRegion,
    setSelectedRegion
} from 'src/store/slices/regionSlice';
import {
    selectThemeMode,
    setTheme as setThemeInStore
} from 'src/store/slices/themeSlice';
import { selectUser } from 'src/store/slices/userSlice';
import { ErrorResponse } from 'src/types/APIResponseTypes';

import {
    StyledTopBar,
    ProfileContainer,
    Spacer,
    StyledHeader,
    UserNameContainer,
    UserEmailContainer,
    StyledAvatar,
    DarkModeMenuItem
} from './TopBar.style';

export const TopBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const user = useSelector(selectUser);
    const regions = useSelector(selectRegions);
    const selectedRegion = useSelector(selectSelectedRegion);
    const selectedTheme = useSelector(selectThemeMode);
    const navigate = useNavigate();

    useEffect(() => {
        if (!selectedRegion && regions.length)
            dispatch(setSelectedRegion(regions[0]?.id));
    }, [regions]);

    const logoutClickHandler = () => {
        logout()
            .then(() => {
                navigate(LOGIN, { replace: true });
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
            });
    };

    const tooltipContent = (
        <>
            <StyledHeader>
                <UserNameContainer>
                    {`${user?.firstname} ${user?.lastname}`}
                </UserNameContainer>
                <UserEmailContainer>{user?.username}</UserEmailContainer>
            </StyledHeader>
            <MenuItem
                component={Link}
                onClick={() => setIsOpen(false)}
                to={MY_PROFILE}
            >
                {PROFILE}
            </MenuItem>
            <MenuItem onClick={() => setIsOpen(false)}>
                <DarkModeMenuItem>
                    Dark Mode
                    <Switch
                        checked={selectedTheme === 'dark'}
                        onChange={() => {
                            const newTheme =
                                selectedTheme === 'dark' ? 'light' : 'dark';
                            localStorage.setItem('armis-theme', newTheme);
                            dispatch(setThemeInStore(newTheme));
                        }}
                    />
                </DarkModeMenuItem>
            </MenuItem>
            <MenuItem
                onClick={logoutClickHandler}
                sx={{ borderBottom: 'none' }}
            >
                {LOG_OUT}
            </MenuItem>
        </>
    );

    return (
        <StyledTopBar>
            <ProfileContainer>
                <Spacer />
                {pathname === `${SETTINGS}/${DASHLETS_ASQ}` && (
                    <Tooltip
                        arrow
                        placement="left"
                        title={
                            <div className="tooltip-arrow-text">
                                {REGIONS_LABEL}
                            </div>
                        }
                    >
                        <Select
                            defaultValue={selectedRegion}
                            MenuProps={{
                                sx: { zIndex: 10002 }
                            }}
                            onChange={event => {
                                dispatch(
                                    setSelectedRegion(
                                        event.target.value as string
                                    )
                                );
                            }}
                            sx={{
                                marginRight: 2,
                                width: '200px'
                            }}
                            value={selectedRegion}
                            variant="outlined"
                        >
                            {regions.length === 0 && (
                                <InputLabel
                                    sx={{
                                        opacity: 0.5,
                                        fontSize: '1.4rem',
                                        textAlign: 'center'
                                    }}
                                >
                                    {NO_RESULT_REGIONS}
                                </InputLabel>
                            )}
                            {regions.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Tooltip>
                )}
                <Tooltip
                    arrow
                    leaveDelay={50}
                    onClose={() => setIsOpen(false)}
                    onOpen={() => setIsOpen(true)}
                    open={isOpen}
                    PopperProps={{ placement: 'bottom-end' }}
                    title={tooltipContent}
                >
                    <StyledAvatar sx={{ width: 32, height: 32 }}>
                        {user?.firstname?.charAt(0)}
                    </StyledAvatar>
                </Tooltip>
            </ProfileContainer>
        </StyledTopBar>
    );
};
