export const PROPERTY_FIELD_TYPE_MAP = {
    TEXT: 'text',
    SCHEDULER: 'scheduler',
    NUMBER: 'number',
    DROPDOWN: 'dropdown',
    TEXTAREA: 'textarea',
    PASSWORD: 'password'
};

export const Privileges = {
    read: 'read',
    create: 'create',
    edit: 'edit',
    delete: 'delete',
    auditLog: 'auditLog',
    createreport: 'createReport',
    deletereport: 'deleteReport',
    editreport: 'editReport'
};

export const Resources = {
    partner: 'partner',
    users: 'users',
    roles: 'roles',
    settings: 'settings',
    tenant: 'tenant'
};
