import { Chips, Tags } from '@armis/armis-ui-library';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import moment, { MomentInput } from 'moment';
import { LOGIN, QUERY_PREFIX, SSO } from 'src/constants/APIConstants';
import { ACTIVE, INACTIVE, PARTNERS } from 'src/constants/LabelText';
import {
    activeCollectorRedirectLink,
    activeIntegrationRedirectLink,
    alertRedirectLink,
    collectorRedirectLink,
    deviceRedirectLink,
    draftIntegrationRedirectLink,
    inActiveIntegrationRedirectLink,
    initializingCollectorRedirectLink,
    integrationRedirectLink,
    pendingActivationCollectorRedirectLink,
    policyRedirectLink,
    sitesRedirectLink
} from 'src/constants/TableConstants';

import { StyledAnchorTag, StyledLink } from './Common.style';

export const urlFilter = (url: string) => url.replace(/\/+$/g, '');

export const linkComp = (content: string, href: string) => (
    <StyledAnchorTag href={href} rel="noreferrer" target="_blank">
        {content}
    </StyledAnchorTag>
);

const renderTags = (items: any, showAsChip: boolean = true, maxTag = 2) => (
    <Tags
        color="default"
        items={items}
        showAsChip={showAsChip}
        showMaxTag={maxTag}
        size="small"
        variant="outlined"
    />
);

export const MasterColumnsConfig: ColDef[] = [
    {
        headerName: '',
        resizable: false,
        suppressMovable: true,
        suppressSizeToFit: true,
        type: '',
        headerClass: 'no-border-right cell-small',
        checkboxSelection: true,
        field: 'checkbox',
        headerCheckboxSelection: true,
        maxWidth: 40,
        cellClass: ['cell-small']
    },
    {
        headerName: '',
        resizable: false,
        suppressMovable: true,
        suppressSizeToFit: true,
        type: '',
        field: 'masterDetail',
        cellRenderer: 'agGroupCellRenderer',
        maxWidth: 40,
        headerClass: 'cell-small',
        cellClass: ['cell-small']
    }
];

export const partnersColumnDef = [
    {
        field: 'name',
        headerName: 'Name',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.name,
                `${params.data.partnerURL}?partner=${params.data.id}`
            )
    },
    {
        field: 'salesForceId',
        headerName: 'Salesforce ID'
    },
    {
        field: 'status',
        headerName: 'Status',
        cellStyle: {
            textAlign: 'center'
        },
        cellRenderer: ({ data: { status } }: ICellRendererParams) => (
            <Chips
                color={
                    status === ACTIVE.toUpperCase() ? 'success' : 'secondary'
                }
                label={status === ACTIVE.toUpperCase() ? ACTIVE : INACTIVE}
                size="small"
                variant="outlined"
            />
        )
    },
    {
        field: 'regionDto',
        headerName: 'Region'
    },
    {
        field: 'adminEmail',
        headerName: 'Admin Email',
        sortable: false
    },
    {
        field: 'adminFirstName',
        headerName: 'Admin Firstname',
        sortable: false
    },
    {
        field: 'adminLastName',
        headerName: 'Admin Lastname',
        sortable: false
    },
    {
        field: 'tenantCount',
        headerName: 'Tenant Count',
        sortable: false,
        cellRenderer: (params: ICellRendererParams) => (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around'
                }}
            >
                <StyledLink
                    state={{ name: params.data?.name }}
                    to={`/${PARTNERS}/${params.data?.id}`}
                >
                    <span>{params.data?.tenantCount}</span>
                </StyledLink>
            </div>
        )
    }
];

export const partnerTenantsColumnDef = [
    {
        field: 'name',
        headerName: 'Name',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.name,
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            )
    },
    {
        field: 'createddate',
        headerName: 'Associated Date',
        cellRenderer: (params: ICellRendererParams) =>
            moment(params.data.createddate as MomentInput).format(
                'MMM D, YYYY h:m:ss A'
            )
    },
    {
        field: 'createdby',
        headerName: 'Associated By',
        sortable: false
    },
    {
        field: 'tenantUrl',
        headerName: 'Tenant URL',
        sortable: false,
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.tenantUrl,
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            )
    }
];

export const auditLogsColumnDef: ColDef[] = [
    {
        field: 'createdon',
        headerName: 'Time',
        cellRenderer: (params: ICellRendererParams) =>
            moment(params.data.createdon as MomentInput).format(
                'MMM D, YYYY h:mm:ss A'
            ),
        sortable: true,
        filter: true,
        filterParams: {
            type: 'date'
        }
    },
    {
        field: 'actiontype',
        headerName: 'Trigger'
    },
    {
        field: 'action',
        headerName: 'Action',
        filter: true
    },
    {
        field: 'username',
        headerName: 'User',
        filter: true
    },
    {
        field: 'userip',
        headerName: 'User IP'
    },
    {
        field: 'info',
        headerName: 'Info'
    }
];

export const userManagementDef: ColDef[] = [
    ...MasterColumnsConfig.slice(0, 1),
    {
        field: 'firstname',
        headerName: 'Name',
        cellRenderer: (params: ICellRendererParams) =>
            `${params.data.firstname} ${params.data.lastname}`,
        sortable: true
    },
    {
        field: 'username',
        headerName: 'Email',
        sortable: true
    },
    {
        field: 'roles',
        headerName: 'Roles',
        cellRenderer: (params: ICellRendererParams) =>
            params.data.roles.map((item: any) => item.name).join(', '),
        sortable: false
    },
    {
        field: 'lastloggedin',
        headerName: 'Last Login Time',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.data.lastloggedin &&
            moment(params.data.lastloggedin as MomentInput).format(
                'MMM D, YYYY hh:mm A'
            ),
        sortable: true
    }
];

export const aggregatedViewDef = [
    {
        field: 'name',
        headerName: 'Partner Name',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.partnerDto?.name,
                `${params.data.regionUrl}?partner=${params.data.partnerDto?.id}`
            )
    },
    {
        field: 'region',
        headerName: 'Host Region'
    },
    {
        field: 'tenantCount',
        headerName: 'Tenants',
        cellRenderer: (params: ICellRendererParams) => (
            <StyledLink to={params.data.partnerDto.id}>
                {params.value}
            </StyledLink>
        ),
        sortable: false
    },
    {
        field: 'device',
        headerName: 'Devices'
    },
    {
        field: 'site',
        headerName: 'Sites'
    },
    {
        field: 'activePolicy',
        headerName: 'Active Policies'
    },
    {
        field: 'alert',
        headerName: 'Alerts'
    },
    {
        field: 'integration',
        headerName: 'Integrations'
    },
    {
        field: 'collector',
        headerName: 'Collectors'
    },
    {
        field: 'logins',
        headerName: 'Logins'
    },
    {
        field: 'lastAccessDate',
        headerName: 'Last Login Time',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY hh:mm A')
    }
];

export const tenantAggregationViewDef = [
    {
        field: 'name',
        headerName: 'Tenant Name',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.tenantDto.name,
                `${urlFilter(params.data.tenantDto.tenantUrl)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            ),
        headerClass: 'header-no-top-border'
    },
    {
        field: 'device',
        headerName: 'Devices',
        headerClass: 'header-no-top-border',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.device,
                `${urlFilter(params.data.tenantDto.tenantUrl)}${
                    params.context.isArmisUser
                        ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                        : ''
                }${deviceRedirectLink}`
            )
    },
    {
        field: 'site',
        headerName: 'Sites',
        headerClass: 'header-no-top-border',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.site,
                `${urlFilter(params.data.tenantDto.tenantUrl)}${
                    params.context.isArmisUser
                        ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                        : ''
                }${sitesRedirectLink}`
            )
    },
    {
        field: 'alert',
        headerName: 'Alerts',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.alert,
                `${urlFilter(params.data.tenantDto.tenantUrl)}${
                    params.context.isArmisUser
                        ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                        : ''
                }${alertRedirectLink}`
            ),
        headerClass: 'header-no-top-border'
    },
    {
        field: 'activePolicy',
        headerName: 'Active Policies',
        headerClass: 'header-no-top-border',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.activePolicy,
                `${urlFilter(params.data.tenantDto.tenantUrl)}${
                    params.context.isArmisUser
                        ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                        : ''
                }${policyRedirectLink}`
            )
    },
    {
        field: 'Integrations',
        headerClass: 'row-separator',
        children: [
            {
                field: 'activeIntegration',
                headerName: 'Active',

                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.activeIntegration,
                        `${urlFilter(params.data.tenantDto.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${activeIntegrationRedirectLink}`
                    )
            },
            {
                field: 'inactiveIntegration',
                headerName: 'Inactive',

                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.inactiveIntegration,
                        `${urlFilter(params.data.tenantDto.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${inActiveIntegrationRedirectLink}`
                    )
            },
            {
                field: 'draftIntegration',
                headerName: 'Draft',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.draftIntegration,
                        `${urlFilter(params.data.tenantDto.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${draftIntegrationRedirectLink}`
                    )
            },
            {
                field: 'errorIntegration',
                headerName: 'Error',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.errorIntegration,
                        `${urlFilter(params.data.tenantDto.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${integrationRedirectLink}`
                    )
            }
        ]
    },
    {
        field: 'Collectors',
        headerClass: 'row-separator',
        children: [
            {
                field: 'activeCollector',
                headerName: 'Active',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.activeCollector,
                        `${urlFilter(params.data.tenantDto.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${activeCollectorRedirectLink}`
                    )
            },
            {
                field: 'offlineCollector',
                headerName: 'Offline',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.offlineCollector,
                        `${urlFilter(params.data.tenantDto.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${collectorRedirectLink}`
                    )
            },
            {
                field: 'initializingCollector',
                headerName: 'Initializing',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.initializingCollector,
                        `${urlFilter(params.data.tenantDto.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${initializingCollectorRedirectLink}`
                    )
            },
            {
                field: 'pendingActivationCollector',
                headerName: 'Pending Activation',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.pendingActivationCollector,
                        `${urlFilter(params.data.tenantDto.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${pendingActivationCollectorRedirectLink}`
                    )
            }
        ]
    },
    {
        field: 'tenantUrl',
        headerName: 'Tenant URL',
        sortable: false,
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                urlFilter(params.data.tenantDto.tenantUrl),
                `${urlFilter(params.data.tenantDto.tenantUrl)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            ),
        headerClass: 'header-no-top-border'
    }
];

export const reportTemplateColumnsConfig: ColDef[] = [
    {
        field: 'name',
        headerName: 'Name'
    },
    {
        field: 'title',
        headerName: 'Title'
    },
    {
        field: 'tags',
        headerName: 'Tags',
        cellStyle: {
            padding: '12px 5px 0px 5px'
        },
        cellRenderer: (params: ICellRendererParams) => {
            if (params.value?.length) {
                return renderTags(
                    params.value.map((tag: string) => ({
                        id: tag,
                        label: tag
                    }))
                );
            }
            return '';
        },
        sortable: false
    },
    {
        field: 'createdby',
        headerName: 'Created By'
    },
    {
        field: 'updateddate',
        headerName: 'Last Modified',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A')
    }
];
