import {
    USER,
    LOGOUT,
    VERIFY_PASSWORD,
    CHANGE_PASSWORD,
    GET_ROLE_STRUCTURE,
    GET_ROLES,
    CREATE_ROLE,
    DELETE_ROLES,
    UPDATE_ROLE,
    GET_AUDIT_LOGS,
    EXPORT_AUDIT_LOGS,
    FILTER_AUDIT_LOGS,
    GET_REGIONS,
    PARTNERS_ENDPOINT,
    PARTNER_TENANTS_ENDPOINT,
    PARTNER_TENANTS_FORM_ENDPOINT,
    DELETE_BULK_USER,
    USERS,
    SAML2_ENDPOINT,
    AGGREGATIONS,
    GET_DASHLETS_ASQ,
    GET_REPORT_TEMPLATE
} from 'src/constants/APIConstants';
import {
    UpdateUserPayload,
    VerifyPasswordPayload,
    ChangePasswordPayload,
    CreateRolePayload,
    ExportAuditLogPayload,
    PartnerTenatsForm,
    AddUserPayload,
    DeleteBulkUsers,
    DeleteUserPayload,
    DashletAsqPayload
} from 'src/types/APIPayloadTypes';
import { User } from 'src/types/APIResponseTypes';

import { axiosInstance } from './axiosInstance';

export const getUser = () => axiosInstance.get<User>(USER);

export const updateUser = (data: UpdateUserPayload) =>
    axiosInstance.post(USER, data);

export const verifyPassword = (data: VerifyPasswordPayload) =>
    axiosInstance.post(VERIFY_PASSWORD, data);

export const changePassword = (data: ChangePasswordPayload) =>
    axiosInstance.post(CHANGE_PASSWORD, data);

export const logout = () => axiosInstance.get(LOGOUT);

// RBAC
export const getRoleStructure = () => axiosInstance.get(GET_ROLE_STRUCTURE);
export const getRoles = (searchValue: string) =>
    axiosInstance.get(
        `${GET_ROLES}?searchBy=${encodeURIComponent(searchValue)}`
    );
export const createRole = (data: CreateRolePayload) =>
    axiosInstance.post(CREATE_ROLE, data);
// This below change of post request for delete we have changed as per Armis review.

export const deleteRoles = (data: string[]) =>
    axiosInstance.post(DELETE_ROLES, data);
export const updateRole = (id: string, data: CreateRolePayload) =>
    axiosInstance.post(`${UPDATE_ROLE}/${id}`, data);

// Audit Logs
export const getAuditLogsData = (params: string) =>
    axiosInstance.get(`${GET_AUDIT_LOGS}?${params}`);
export const exportAuditLog = (data: ExportAuditLogPayload, params: string) =>
    axiosInstance.post(`${EXPORT_AUDIT_LOGS}?${params}`, data, {
        responseType: 'blob'
    });

export const getAuditLogsFilterColumns = () =>
    axiosInstance.get(FILTER_AUDIT_LOGS);

// partners
export const getPartnersData = (params: string) =>
    axiosInstance.get(`${PARTNERS_ENDPOINT}?${params}`);

export const getRegions = () => axiosInstance.get(`${GET_REGIONS}`);

export const postFormDATA = (data: any) =>
    axiosInstance.post(`${PARTNERS_ENDPOINT}`, data);

export const activatePartner = (partnerID: string, activate: boolean) =>
    axiosInstance.post(`${PARTNERS_ENDPOINT}/${partnerID}/${activate}`);

export const deletePartner = (partnerID: string) =>
    axiosInstance.delete(`${PARTNERS_ENDPOINT}/${partnerID}`);

export const editPartner = (partnerID: string, data: any) =>
    axiosInstance.post(`${PARTNERS_ENDPOINT}/${partnerID}`, data);

export const getPartnerByID = (partnerID: string) =>
    axiosInstance.get(`${PARTNERS_ENDPOINT}/${partnerID}`);

// partnerTenants
export const getPartnerTenants = (params: string, partnerID: string) =>
    axiosInstance.get(`${PARTNER_TENANTS_ENDPOINT}/${partnerID}?${params}`);

export const editPartnerTenant = (tenantID: string, data: PartnerTenatsForm) =>
    axiosInstance.patch(`${PARTNER_TENANTS_FORM_ENDPOINT}/${tenantID}`, data);

export const postPartnerTenant = (data: PartnerTenatsForm) =>
    axiosInstance.post(`${PARTNER_TENANTS_FORM_ENDPOINT}`, data);

export const deleteTenantByID = (tenantID: string) =>
    axiosInstance.delete(`${PARTNER_TENANTS_FORM_ENDPOINT}/${tenantID}`);

// User Management
export const getUserData = (params: string) =>
    axiosInstance.get(`${USERS}?${params}`);
export const addUserData = (data: AddUserPayload) =>
    axiosInstance.post(`${USERS}`, data);
export const deleteUser = (id: string, data: DeleteUserPayload) =>
    axiosInstance.delete(`${USERS}/${id}`, { data });
export const getUserDetails = (id: string) =>
    axiosInstance.get(`${USERS}/${id}`);
export const deleteBulkUsers = (data: DeleteBulkUsers) =>
    axiosInstance.post(`${DELETE_BULK_USER}`, data);
export const updateUserDetails = (id: string, data: AddUserPayload) =>
    axiosInstance.post(`${USERS}/${id}`, data);

// SAML
export const loginWithSAML2 = () =>
    `${axiosInstance.defaults.baseURL}${SAML2_ENDPOINT}`;

// Aggregated View
export const getAggregationData = (params: string, isRefresh: boolean) =>
    axiosInstance.get(`${AGGREGATIONS}?${params}&isRefresh=${isRefresh}`);
export const getTenantAggregationData = (id: string, params: string) =>
    axiosInstance.get(`${AGGREGATIONS}/${id}?${params}`);
export const getPartnerDetailsByID = (id: string) =>
    axiosInstance.get(`${PARTNERS_ENDPOINT}/${id}`);

// Dashboard ASQ
export const getDashletsASQs = (regionId: string) =>
    axiosInstance.get(`${GET_DASHLETS_ASQ}/${regionId}`);
export const createDashletASQ = (data: DashletAsqPayload) =>
    axiosInstance.post(`${GET_DASHLETS_ASQ}`, data);
export const editDashletASQ = (id: string, data: DashletAsqPayload) =>
    axiosInstance.post(`${GET_DASHLETS_ASQ}/${id}`, data);
export const deleteASQ = (id: string, regionId: string) =>
    axiosInstance.delete(`${GET_DASHLETS_ASQ}/${id}/${regionId}`);

// Report Template
export const getReportTemplate = (params: string) =>
    axiosInstance.get(`${GET_REPORT_TEMPLATE}?${params}`);
// export const deleteReportTemplate = (data: string[]) =>
//     axiosInstance.post(`${DELETE_BULK_REPORT_TEMPLATE}`, data);
export const deleteSingleReportTemplate = (reportId: string) => axiosInstance.delete(`${GET_REPORT_TEMPLATE}/${reportId}`);
export const getSingleReportTemplate = (reportId: string) => axiosInstance.get(`${GET_REPORT_TEMPLATE}/${reportId}`);
export const updateSingleReportTemplate = (reportId: string, data: any) => axiosInstance.post(`${GET_REPORT_TEMPLATE}/${reportId}`,data);
export const createReportTemplate = (data: any) =>
    axiosInstance.post(`${GET_REPORT_TEMPLATE}`,data);
