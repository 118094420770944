import { useState, useEffect, useRef, useMemo } from 'react';

import {
    CustomHeader,
    IconButton,
    IconDelete,
    IconPencil,
    MoreAction,
    MultiSelect,
    Pagination,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    View,
    WhiteUpload
} from '@armis/armis-ui-library';
import Editor, { OnMount } from '@monaco-editor/react';
import { PaperProps, Menu, Button, useTheme } from '@mui/material';
import {
    ColDef,
    ICellRendererParams,
    SelectionChangedEvent
} from 'ag-grid-community';
import { AxiosError, AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import { LuDownload } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    IMPORT_REPORT,
    AMP_REPORT_TEMPLATES,
    DELETE,
    DELETE_REPORT,
    DELETE_SINGLE_REPORT,
    REPORT,
    REPORT_DELETED_SUCCESS,
    REPORTS,
    SAVE,
    SEARCH_REPORT_PLACEHOLDER,
    VIEW_JSON,
    INVALID_JSON,
    INVALID_JSON_FILE,
    REPORT_UPDATE_SUCCESS,
    REPORT_CREATE_SUCCESS,
    DOWNLOAD_JSON
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    reportTemplateAPIMapping
} from 'src/constants/TableConstants';
import { reportTemplateColumnsConfig } from 'src/helpers/ColumnsConfig';
import {
    TOAST_TYPE,
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    isActionHasPermissions,
    showToast
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import {
    createReportTemplate,
    deleteSingleReportTemplate,
    getReportTemplate,
    getSingleReportTemplate,
    updateSingleReportTemplate
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { ErrorResponse, GenericResponseData } from 'src/types/APIResponseTypes';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { AvailableReportTemplateActions } from './constants';
import { StyledInput } from './ReportTemplate.style';
import { Report, ReportTemplateProps } from './ReportTemplate.types';
import { StyledMenuItem, StyledOption } from '../Partners/Partners.style';

const EDIT_REPORT = 'edit_report';
const templateReportSortOrder: Map<number> = {};
const templateReportSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const columnFilterObj: { [key: string]: FilterItems[] } = {
    tags: []
};
const queryPropertiesObj: { [key: string]: string } = {
    tags: ''
};
createRelatedObject(
    reportTemplateAPIMapping,
    templateReportSortOrder,
    templateReportSortStatus,
    columnsFilterItems
);

const reportTemplateActionOptions = [
    {
        type: AvailableReportTemplateActions.DELETE,
        icon: <IconDelete />,
        label: DELETE,
        privileges: [Privileges.deletereport]
    },
    {
        type: AvailableReportTemplateActions.VIEW_JSON,
        icon: <View height={14} width={14} />,
        label: VIEW_JSON,
        privileges: [Privileges.read]
    },
    {
        type: AvailableReportTemplateActions.DOWNLOAD_JSON,
        icon: <LuDownload />,
        label: DOWNLOAD_JSON,
        privileges: [Privileges.read]
    }
];

const ReportTemplateComponent = ({ setIsLoading }: ReportTemplateProps) => {
    const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(true);

    const paginationRef = useRef<ChildRefProp>();
    const [reportList, setReportList] = useState<Report[]>([]);
    const [totalRows, setTotalRows] = useState<null | number>(null);
    const firstRender = useRef(true);
    const currentUser = useSelector(selectUser);
    const [selectedReports, setselectedReports] = useState<string[]>([]);

    const [selectedReportAction, setSelectedReportAction] = useState('');
    const [selectedReportJSON, setSelectedReportJSON] = useState<JSON>();

    const [anchorElForReportActions, setanchorElForReportActions] =
        useState<null | HTMLElement>(null);
    const selectedReport = useRef<Report | null>(null);

    const theme = useTheme();

    const [refreshPage, setRefreshPage] = useState(false);
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        // setFilterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        modelOpen,
        setModalOpen,
        anchorEl,
        // setAnchorEl,
        onSelectionChanged,
        onFilterChanged,
        queryProperties,
        columnFilterData,
        setColumnFilterData,
        setQueryProperties
        // setColumnSortOrder,
        // setColumnSortStatus
    } = useTable({
        sortOrderObj: templateReportSortOrder,
        sortStatusObj: templateReportSortStatus,
        columnsFilterItems,
        queryPropertiesObj,
        columnFilterObj
    });

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    const searchReportContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue => {
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }));
                    }}
                    placeholder={SEARCH_REPORT_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    const columnConfig = useMemo(
        () => [
            ...reportTemplateColumnsConfig,
            {
                field: 'actions',
                headerName: 'Actions',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                pinned: 'right',
                initialWidth: 120,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (params: ICellRendererParams<Report>) => (
                    <>
                        <IconButton
                            className="Icon-Hover-Effect"
                            disabled={
                                !isActionHasPermissions(
                                    currentUser,
                                    Resources.settings,
                                    [Privileges.editreport]
                                )
                            }
                            onClick={() => {
                                setIsDisabledSubmitBtn(true);
                                setSelectedReportAction(EDIT_REPORT);
                                selectedReport.current = params.data!;
                            }}
                            size="small"
                        >
                            <IconPencil height={16} width={16} />
                        </IconButton>
                        <IconButton
                            className="Icon-Hover-Effect"
                            data-testid="template-actions"
                            disabled={
                                !isActionHasPermissions(
                                    currentUser,
                                    Resources.settings,
                                    [Privileges.read, Privileges.deletereport]
                                )
                            }
                            onClick={e => {
                                setanchorElForReportActions(e.currentTarget);
                                selectedReport.current = params.data!;
                            }}
                            size="small"
                        >
                            <MoreAction />
                        </IconButton>
                    </>
                ),
                sortable: false
            } as ColDef
        ],
        [setModalOpen]
    );

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                onFilterChanged: (
                    columnName: string,
                    selectedItems: FilterItems[]
                ) => {
                    onFilterChanged(columnName, selectedItems);
                    paginationRef.current?.resetPagination();
                },
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true,
            filter: false,
            filterParams: columnFilterData
        }),
        [
            columnSortOrder,
            onSortChangedCall,
            columnSortStatus,
            columnFilterData,
            onFilterChanged
        ]
    );

    const onCloseReportActionMenu = () => {
        setanchorElForReportActions(null);
    };

    const [reportJSON, setreportJSON] = useState<string>();
    const handleEditorChange = (value: string | undefined) => {
        setreportJSON(value);
    };

    const onCloseReportActionModal = () => {
        setModalOpen(false);
        setSelectedReportAction('');
        selectedReport.current = null;
    };

    useEffect(() => {
        if (
            (selectedReportAction ===
                AvailableReportTemplateActions.VIEW_JSON ||
                selectedReportAction === EDIT_REPORT ||
                selectedReportAction ===
                    AvailableReportTemplateActions.DOWNLOAD_JSON) &&
            selectedReport?.current?.id
        ) {
            setIsLoading(true);
            getSingleReportTemplate(selectedReport?.current?.id)
                .then(res => {
                    const reportJson = res.data;
                    delete reportJson.id;
                    delete reportJson.partnerId;
                    delete reportJson.createdby;
                    delete reportJson.updateddate;
                    delete reportJson.tenantCount;

                    for (let i = 0; i < reportJson?.elements?.length; i++) {
                        if (reportJson.elements[i].isReportlet) {
                            delete reportJson.elements[i].reportlet.id;
                            delete reportJson.elements[i].reportlet.reportId;
                            delete reportJson.elements[i].reportlet.partnerId;

                            delete reportJson.elements[i].reportlet
                                .reportDashlet.id;
                            delete reportJson.elements[i].reportlet
                                .reportDashlet.partnerId;
                            delete reportJson.elements[i].reportlet
                                .reportDashlet.createdby;
                            delete reportJson.elements[i].reportlet
                                .reportDashlet.updateddate;
                        } else {
                            delete reportJson.elements[i].reportElement.id;
                            delete reportJson.elements[i].reportElement
                                .reportId;
                            delete reportJson.elements[i].reportElement
                                .partnerId;
                        }
                    }

                    if (
                        selectedReportAction ===
                        AvailableReportTemplateActions.DOWNLOAD_JSON
                    ) {
                        const link = document.createElement('a');

                        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(reportJson)
                        )}`;
                        link?.setAttribute('href', dataStr);

                        link.setAttribute(
                            'download',
                            `${reportJson.name}.json`
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode?.removeChild(link);

                        onCloseReportActionModal();
                    } else {
                        setSelectedReportJSON(reportJson);

                        setModalOpen(true);
                    }
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [selectedReportAction]);

    useEffect(() => {
        setTableLoading(true);

        getReportTemplate(convertQueryObjectToParams(queryProperties))
            .then((res: AxiosResponse<GenericResponseData<Report>>) => {
                setReportList(res.data.content);
                setTotalRows(res.data.totalElements);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
                setReportList([]);
                setTotalRows(0);
            })
            .finally(() => {
                setTableLoading(false);
                gridRef.current?.api.deselectAll();
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                ) {
                    paginationRef.current?.resetPagination();
                }
            });
    }, [queryProperties, setTableLoading, setColumnFilterData, refreshPage]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (reportList.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [reportList, tableLoading, gridRef]);

    const deleteSingleReport = (currentReportId: string) => {
        setTableLoading(true);
        deleteSingleReportTemplate(currentReportId)
            .then(() => {
                setModalOpen(false);
                showToast(REPORT_DELETED_SUCCESS, TOAST_TYPE.SUCCESS, TOAST_ID);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setRefreshPage(prevValue => !prevValue);
                setTableLoading(false);
            });
    };

    const isJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const handleCapture = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = async (evt: any) => {
            const filedata = evt.currentTarget.result;

            if (isJsonString(filedata)) {
                setIsLoading(true);
                createReportTemplate(JSON.parse(filedata))
                    .then(res => {
                        showToast(
                            REPORT_CREATE_SUCCESS.replace('%s', res.data.name),
                            TOAST_TYPE.SUCCESS,
                            TOAST_ID
                        );
                        setRefreshPage(prevValue => !prevValue);
                    })
                    .catch(err => displayErrorMessage(err))
                    .finally(() => {
                        event.target.value = null;
                        setIsLoading(false);
                    });
                onCloseReportActionModal();
            } else {
                showToast(INVALID_JSON_FILE, TOAST_TYPE.ERROR, TOAST_ID);
            }
        };
    };

    const onEditorMount: OnMount = editor => {
        editor.onDidChangeModelContent(
            debounce(() => {
                setIsDisabledSubmitBtn(!isJsonString(editor.getValue()));
            }, 500)
        );
    };

    const rowSelectionChangeHandler = (
        event: SelectionChangedEvent<Report>
    ) => {
        setselectedReports(
            event.api.getSelectedRows().map(report => report.id)
        );
    };

    return (
        <>
            <Header title={AMP_REPORT_TEMPLATES}>
                <ProtectedAction
                    hasPermissions={[Privileges.createreport]}
                    resource={Resources.settings}
                >
                    <div className="file-wrapper">
                        {/* <span className="file-name">{filename}</span> */}
                        <label
                            className="control file-input"
                            htmlFor="file-upload"
                        >
                            <div className="input">
                                <input hidden type="file" />
                                <Button
                                    className="header-add-button"
                                    color="primary"
                                    component="span"
                                    disabled={tableLoading}
                                    onClick={() => {}}
                                    startIcon={<WhiteUpload />}
                                    style={{
                                        width: '155px',
                                        paddingLeft: '15px'
                                    }}
                                    variant="contained"
                                >
                                    {IMPORT_REPORT}
                                </Button>
                            </div>
                        </label>
                        <StyledInput
                            accept=".json"
                            id="file-upload"
                            onChange={handleCapture}
                            type="file"
                        />
                    </div>
                </ProtectedAction>
            </Header>
            <div className="control table">
                <TableHeader
                    childrenLeft={searchReportContainer}
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedReports.length}
                    title={`${totalRows} ${
                        totalRows === 1 ? `${REPORT}` : `${REPORTS}`
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Menu
                    anchorEl={anchorElForReportActions}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    onClose={onCloseReportActionMenu}
                    open={Boolean(anchorElForReportActions)}
                    PaperProps={
                        {
                            component: StyledMenuPaper,
                            sx: {
                                marginTop: '0px !important'
                            }
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {reportTemplateActionOptions.map(action => (
                        <ProtectedAction
                            key={action.type}
                            hasAnyPermission={action.privileges}
                            resource={Resources.settings}
                        >
                            <StyledMenuItem
                                key={action.type}
                                onClick={() => {
                                    onCloseReportActionMenu();
                                    setSelectedReportAction(action.type);
                                    if (
                                        action.type ===
                                        AvailableReportTemplateActions.DELETE
                                    ) {
                                        setModalOpen(true);
                                    }
                                }}
                            >
                                {action.icon}
                                <StyledOption> {action.label} </StyledOption>
                            </StyledMenuItem>
                        </ProtectedAction>
                    ))}
                </Menu>

                {modelOpen &&
                    selectedReportAction ===
                        AvailableReportTemplateActions.DELETE && (
                        <Modal
                            displayBtn="all"
                            isModalOpen={modelOpen}
                            onCancel={() => {
                                setModalOpen(false);
                                onCloseReportActionModal();
                            }}
                            onSubmit={() => {
                                setModalOpen(false);
                                deleteSingleReport(
                                    selectedReport?.current?.id || ''
                                );
                            }}
                            submitBtnLabel={DELETE}
                            title={DELETE_REPORT}
                        >
                            <WarningModalContainer
                                text={DELETE_SINGLE_REPORT.replace(
                                    '%s',
                                    selectedReport.current?.name || ''
                                )}
                            />
                        </Modal>
                    )}

                {modelOpen &&
                    selectedReportAction ===
                        AvailableReportTemplateActions.VIEW_JSON && (
                        <Modal
                            className="x-wide"
                            displayBtn="cancel"
                            isModalOpen={modelOpen}
                            onCancel={() => {
                                setModalOpen(false);
                                onCloseReportActionModal();
                            }}
                            onSubmit={() => {
                                setModalOpen(false);
                            }}
                            submitBtnLabel={DELETE}
                            title={selectedReport?.current?.name || ''}
                        >
                            <Editor
                                defaultLanguage="json"
                                defaultValue={JSON.stringify(
                                    selectedReportJSON,
                                    null,
                                    2
                                )}
                                height="50vh"
                                options={{
                                    readOnly: true
                                }}
                                theme={
                                    theme.palette.mode === 'dark'
                                        ? 'vs-dark'
                                        : ''
                                }
                            />
                        </Modal>
                    )}

                {modelOpen && selectedReportAction === EDIT_REPORT && (
                    <Modal
                        className="x-wide"
                        displayBtn="all"
                        isDisabledSubmitBtn={isDisabledSubmitBtn}
                        isModalOpen={modelOpen}
                        onCancel={() => {
                            onCloseReportActionModal();
                        }}
                        onSubmit={() => {
                            if (isJsonString(reportJSON)) {
                                setIsLoading(true);
                                updateSingleReportTemplate(
                                    selectedReport?.current?.id || '',
                                    JSON.parse(reportJSON || '')
                                )
                                    .then(res => {
                                        showToast(
                                            REPORT_UPDATE_SUCCESS.replace(
                                                '%s',
                                                res.data.name
                                            ),
                                            TOAST_TYPE.SUCCESS,
                                            TOAST_ID
                                        );
                                        setRefreshPage(prevValue => !prevValue);
                                    })
                                    .catch(err => displayErrorMessage(err))
                                    .finally(() => setIsLoading(false));
                                onCloseReportActionModal();
                            } else {
                                showToast(
                                    INVALID_JSON,
                                    TOAST_TYPE.ERROR,
                                    TOAST_ID
                                );
                            }
                        }}
                        submitBtnLabel={SAVE}
                        title={selectedReport?.current?.name || ''}
                    >
                        <Editor
                            defaultLanguage="json"
                            defaultValue={JSON.stringify(
                                selectedReportJSON,
                                null,
                                2
                            )}
                            height="50vh"
                            onChange={handleEditorChange}
                            onMount={onEditorMount}
                            options={
                                {
                                    // readOnly: true
                                }
                            }
                            theme={
                                theme.palette.mode === 'dark' ? 'vs-dark' : ''
                            }
                        />
                    </Modal>
                )}

                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onSelectionChanged={rowSelectionChangeHandler}
                    rowData={reportList}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <span>
                        <Pagination
                            ref={paginationRef}
                            onPaginationChanged={(page, size) => {
                                setQueryProperties(prevValue => ({
                                    ...prevValue,
                                    page,
                                    size
                                }));
                            }}
                            totalRowsCount={totalRows}
                        />
                    </span>
                )}
            </div>
        </>
    );
};

export const ReportTemplate = IsLoadingHOC(ReportTemplateComponent);
