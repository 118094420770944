import React, { useState } from 'react';

import { CHECKBOX_STATUS, MultiSelect, Select } from '@armis/armis-ui-library';
import { MenuItem } from '@mui/material';
import { cloneDeep } from 'lodash';
import {
    EXPORT_COLUMN_TO_INCLUDE_LABEL,
    EXPORT_CSV,
    EXPORT_EXCEL,
    EXPORT_MODAL_CONTENT_CSV_SPAN,
    EXPORT_MODAL_CONTENT_SPAN,
    EXPORT_MODAL_CSV_CONTENT,
    EXPORT_MODAL_CSV_SPAN,
    EXPORT_OUTPUT_FORMAT_LABEL,
    EXPORT_XLSX_MAX_VALUE
} from 'src/constants/LabelText';

import {
    StyledCSVText,
    StyledLabel,
    StyledLinkedCSV,
    StyledSection,
    StyledSpan,
    StyledText
} from './ExportModalContainer.style';

export const ExportModalContainer = ({
    text,
    items,
    noOfAuditLogs,
    outputFormat,
    columnsToBeExported,
    defaultValue
}: {
    text: string;
    items: any;
    noOfAuditLogs: number;
    outputFormat: any;
    columnsToBeExported: any;
    defaultValue: any;
}) => {
    const columnItems = cloneDeep(items);

    const [outputFormatValue, setOutputFormatValue] = useState('XLSX');
    const [columnSelect, setColumnSelected] = useState(defaultValue);
    const [coulumnsToInclude, setCoulumnsToInclude] = useState(columnItems);

    const handleSelectOutputFormat = (e: any) => {
        e.preventDefault();
        setOutputFormatValue(e.target.value);
    };

    const handleCsvSelection = () => {
        setOutputFormatValue('CSV');
    };

    const handleColumnChange = (columnsData: any) => {
        const totalColumnsSelected = columnsData.length;
        if (totalColumnsSelected === items.length) {
            setColumnSelected(['All']);
        } else {
            setColumnSelected([`${totalColumnsSelected} Selected`]);
        }
    };

    return (
        <>
            <StyledText>
                {text}
                {noOfAuditLogs > EXPORT_XLSX_MAX_VALUE &&
                    outputFormatValue === 'XLSX' ? (
                    <>
                        <StyledSpan>
                            {' '}
                            {EXPORT_MODAL_CONTENT_CSV_SPAN}
                            {noOfAuditLogs}.
                        </StyledSpan>
                        <StyledCSVText>
                            <span>{EXPORT_MODAL_CSV_CONTENT}</span>
                            <StyledLinkedCSV>
                                <a onClick={handleCsvSelection}>
                                    {EXPORT_MODAL_CSV_SPAN}
                                </a>
                            </StyledLinkedCSV>
                        </StyledCSVText>
                    </>
                ) : (
                    <StyledSpan>
                        {' '}
                        {noOfAuditLogs} {EXPORT_MODAL_CONTENT_SPAN}
                    </StyledSpan>
                )}
            </StyledText>
            <StyledSection>
                <StyledLabel>{EXPORT_OUTPUT_FORMAT_LABEL}</StyledLabel>
                <Select
                    defaultValue={outputFormatValue}
                    MenuProps={{
                        sx: { zIndex: 10002 },
                        PaperProps: {
                            sx: {
                                minWidth: '250px !important',
                            }
                        }
                    }}
                    onChange={handleSelectOutputFormat}
                    onSelectCapture={outputFormat(outputFormatValue)}
                    value={outputFormatValue}
                    variant="outlined"
                >
                    <MenuItem value="XLSX">{EXPORT_EXCEL}</MenuItem>
                    <MenuItem value="CSV">{EXPORT_CSV}</MenuItem>
                </Select>
            </StyledSection>
            <StyledSection sx={{ paddingBottom: 0 }}>
                <StyledLabel>{EXPORT_COLUMN_TO_INCLUDE_LABEL}</StyledLabel>
                <Select
                    defaultValue="All"
                    MenuProps={{
                        sx: { zIndex: 10002 },
                        PaperProps: {
                            sx: {
                                minWidth: '250px !important',
                                maxHeight: '268px'
                            }
                        }
                    }}
                    renderValue={() => columnSelect}
                    value={columnSelect}
                    variant="outlined"
                >
                    <MultiSelect
                        items={coulumnsToInclude}
                        onSelectionChanged={selectedItems => {
                            const columnsData = selectedItems.map(element => ({
                                [element.label]: element.id
                            }));
                            const columnsInObject = Object.assign(
                                {},
                                ...columnsData
                            );
                            const updatedColumnsToInclude =
                                coulumnsToInclude.filter((element: any) =>
                                    !selectedItems.includes(element)
                                        ? (element.checkStatus =
                                            CHECKBOX_STATUS.UNCHECKED)
                                        : (element.checkStatus =
                                            CHECKBOX_STATUS.CHECKED)
                                );
                            setCoulumnsToInclude(updatedColumnsToInclude);
                            columnsToBeExported(columnsInObject);
                            handleColumnChange(columnsData);
                        }}
                        showSelectAllOption
                    />
                </Select>
            </StyledSection>
        </>
    );
};
