// SideNavBar labels
export const ANALYTICS_LABEL = 'Analytics';
export const REPORTS_LABEL = 'Reports';
export const SETTINGS_LABEL = 'Settings';
export const USER_MANAGEMENT_LABEL = 'User Management';
export const NOTIFICATIONS_LOGGING = 'Notifications & Logging';

// Common
export const ARMIS = 'Armis';
export const PASSWORD_REGEX =
    '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8,30}$';
export const PHONE_REGEX = '^\\d{0,15}$';
export const EMAIL_REGEX = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
export const TENANTS = 'Tenants';
export const TENANT = 'Tenant';

export const MIN_VALUE_INVALID = 'Min value should be %s.';
export const MAX_VALUE_INVALID = 'Max value should be %s.';
export const MAX_LENGTH_INVALID = 'Max length should be %s.';
export const INVALID_VALUE_INSERT = 'This value is not supported.';
export const INVALID_URL = 'Please enter a valid url';
export const OPTIONAL = '(optional)';
export const TIMEOUT_ERROR = 'The server is busy. Please try again later.';
export const SESSION_EXPIRED = 'Session expired';
export const LOG_OUT = 'Log out';

// Login
export const LOGIN_TITLE = 'Login';

// Basic Auth
export const BA_FORM_TITLE = 'Log in to your Armis account';
export const PASSWORD_PLACEHOLDER = 'Password';
export const LOGIN_WITH_SSO = 'Login with SSO';
export const NEXT = 'Next';
export const READ_OUR = 'Read our ';
export const PRIVACY_POLICY = 'Privacy policy';
export const REQUIRED = 'Required field is missing.';

// Home
export const HOME_TITLE = 'Armis';

// My Profile
export const MY_PROFILE = 'My Profile';
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const USERNAME = 'Username';
export const EMAIL = 'Email';
export const PHONE = 'Phone';
export const CONFIRM_ACTION = 'Confirm action';
export const CHANGE_PASSWORD = 'Change Password';
export const CHANGE = 'Change';
export const SUBMIT = 'Submit';
export const OK = 'OK';
export const CANCEL = 'Cancel';
export const DELETE = 'Delete';
export const DONE = 'Done';
export const TYPE_PASSWORD = 'Type password';
export const RETYPE_PASSWORD = 'Retype password';
export const NEVER_CHANGED = 'Never changed';
export const LAST_CHANGED = 'Last changed';
export const DUMMY_PASSWORD = '12345678';
export const CONFIRM_ACTION_MODAL_TEXT_1 =
    "The action you're about to perform requires a password.";
export const CONFIRM_ACTION_MODAL_TEXT_2 =
    'Please enter your current password:';
// eslint-disable-next-line max-len
export const CHANGE_PASSWORD_MODAL_TEXT_1 = `You're about to set a new password. The password must be between 8 and 30 characters and include at least one lowercase character, one uppercase character, one number, and one special character.`;
export const PHONE_VALIDATE =
    'Maxim of 20 characters are allowed for a phone number.';
export const FIRST_NAME_VALIDATE =
    'First name should not be empty and contain up to 100 characters.';
export const LAST_NAME_VALIDATE =
    'Last name should not be empty and contain up to 100 characters.';
export const PASSWORD_VALIDATE =
    'The password must be between 8 and 30 characters and include at least one lowercase character, one uppercase character, one number, and one special character.';

// Role Management
export const ROLE_MANAGEMENT = 'Roles & Permissions';
export const PREDEFINED_ROLES = 'Predefined Roles';
export const CUSTOM_ROLES = 'Custom Roles';
export const SHOW_ALL = 'Show all';
export const SHOW_LESS = 'Show less';
export const USERS = 'user(s)';
export const USER = 'User';
export const ASSIGNED = 'assigned';
export const SAVE = 'Save';
export const DISCARD = 'Discard';
export const CLOSE = 'Close';
export const NO_USER = 'No users assigned';
export const SEARCH_ROLE_PLACEHOLDER = 'Search roles';
export const DELETE_ROLES = 'Delete Roles';
export const DELETE_ROLE = 'Delete Role';
export const SELECTED = 'selected';
export const ADD_ROLE = 'Add role';
export const DEFAULT_ROLE_NAME = 'Role Name';
export const ROLE_UPDATE_SUCCESS = 'Role "%s" has been edited successfully.';
export const ROLE_CREATE_SUCCESS = 'Role "%s" has been added successfully.';
export const DUPLICATE_ROLE_SUCCESS =
    'Role "%s" has been duplicated successfully and is called "%s".';
export const DELETE_ROLE_SUCCESS = 'Role "%s" has been deleted successfully.';
export const DELETE_ROLES_SUCCESS = '%s roles are deleted successfully.';
export const DELETE_ROLE_MESSAGE =
    'You are about to delete role "%s". Are you sure?';
export const DELETE_ROLES_MESSAGE =
    'You are about to delete %s user roles. Are you sure?';
export const DELETE_ROLE_NOT_ALLOWED =
    'Cannot delete a role with assigned users. Reassign the users to another role in the User Management tab and retry.';
export const DELETE_ROLES_NOT_ALLOWED =
    'Cannot complete the action because one or more roles are assigned to users. Assign different roles to affected users in the User Management tab and retry.';
export const USER_MANAGEMENT_TAB = 'User Management tab';
export const ROLE_NAME_ERROR_EMPTY = 'This field is required.';
export const ROLE_NAME_ERROR_MAXLENGTH =
    'Max 100 characters are allowed in role name.';
export const ROLE_NAME_PLACEHOLDER = 'Enter a role.';

// Audit Log
export const AUDIT_LOG_HEADER = 'Audit Log';
export const AUDIT_LOG_TITLE = 'Audit Logs';
export const EXPORT = 'Export';
export const EXPORT_MODAL_TITLE = 'Export Report';
export const EXPORT_MODAL_CONTENT = 'This report will include';
export const EXPORT_MODAL_CONTENT_SPAN = 'audit logs.';
export const EXPORT_MODAL_CONTENT_CSV_SPAN = 'the top 2,500 audit logs out of ';
export const EXPORT_MODAL_CSV_CONTENT = 'To include more rows,';
export const EXPORT_MODAL_CSV_SPAN = ' use CSV format.';
export const EXPORT_OUTPUT_FORMAT_LABEL = 'Output Format';
export const EXPORT_COLUMN_TO_INCLUDE_LABEL = 'Columns to include';
export const EXPORT_EXCEL = 'Excel (.xlsx)';
export const EXPORT_CSV = 'Comma Separated Values (.csv)';
export const EXPORT_XLSX_MAX_VALUE = 2500;
export const TRIGGER = 'Trigger';

// Partners
export const PARTNERS = 'Partners';
export const PARTNER = 'Partner';

export const ADD_PARTNER = 'Add Partner';
export const EDIT_PARTNER = 'Edit Partner';
export const PARTNER_NAME = 'Name';
export const PARTNER_SALESFORCE_ID = 'Salesforce ID';
export const PARTNER_FIRST_NAME = 'Firstname';
export const PARTNER_LAST_NAME = 'Lastname';
export const PARTNER_EMAIL = 'Email';
export const PARTNER_PHONE_NUMBER = 'Phone Number';
export const PARTNER_INSTANCE_NAME = 'Name';
export const PARTNER_INSTANCE_REGION = 'Region';
export const PARTNER_INSTANCE_NOTES = 'Notes';
export const PARTNER_DETAILS = '1. Partner Details';
export const ADD_ADMINISTRATOR = '2. Add Admin';
export const PARTNER_REVIEW = '3. Review';
export const PREVIOUS = 'Previous';
export const BACK = 'Back';
export const PARTNER_ADD_SUCCESS = 'Partner "%s" has been added successfully.';
export const PARTNER_EDIT_SUCCESS =
    'Partner "%s" has been edited successfully.';
export const PARTNER_INACTIVATE_SUCCESS =
    'Partner "%s" has been deactivated successfully.';
export const PARTNER_ACTIVATE_SUCCESS =
    'Partner "%s" has been activated successfully.';
export const PARTNER_DELETE_SUCCESS =
    'Partner "%s" has been deleted successfully.';
export const PARTNER_DELETE_TITLE = 'Delete Partner - "%s"';
export const PARTNER_DELETE_MESSAGE =
    'You are about to delete partner "%s". Are you sure?';

export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const EDIT = 'Edit';
export const ARCHIVE = 'Archive';
export const ACTIVATE = 'Active';
export const INACTIVATE = 'Inactive';
export const ADD = 'Add';

// Tenants
export const TENANTS_SEARCH = 'Search tenants';
export const ASSOCIATE_TENANT = 'Associate Tenant';
export const DIASSOCIATE = 'Disassociate';
export const LOADING = 'Loading';
export const TENANT_NAME = 'Name';
export const TENANT_URL = 'URL';
export const SECRET_KEY = 'Secret Key';
export const PASSWORD = 'Password';
export const EDIT_TENANT = 'Edit Tenant';
export const TENANT_USER = 'Tenant User:';
export const TENANT_ADD_SUCCESS = 'Tenant "%s" has been added successfully.';
export const TENANT_EDIT_SUCCESS = 'Tenant "%s" has been edited successfully.';
export const TENANT_DIASSOCIATE_SUCCESS =
    'Tenant "%s" has been disassociated successfully.';
export const WRITE_ONLY_FIELD = 'SECRET KEY';
export const PLACEHOLDER_PASSWORD = '***************';
export const ADMIN_FIRST_NAME = 'adminFirstName';
export const ADMIN_LAST_NAME = 'adminLastName';
export const TENANT_DIASSOCIATE_WARNING =
    'You are about to disassociate tenant "%s". Are you sure?';
export const TENANT_DIASSOCIATE_WARNING_TITLE = 'Disassociate Tenant - "%s"';

// User Management
export const USER_MANAGEMENT_HEADER = 'User';
export const USERS_MANAGEMENT_HEADER = 'Users';
export const ADD_USERS = 'Add User';
export const SEARCH_USER_PLACEHOLDER = 'Search users';
export const EDIT_USER = 'Edit User';
export const DELETE_USERS = 'Delete Users';
export const DELETE_USER = 'Delete User';
export const DELETE_SINGLE_USER =
    'You are about to delete user "%s". Are you sure?';
export const DELETE_MULTIPLE_USER =
    'You are about to delete %s users. Are you sure?';
export const USER_ADDED_SUCCESS = 'User "%s" has been added successfully.';
export const USER_UPDATED_SUCCESS = 'User "%s" has been edited successfully.';
export const USER_DELETED_SUCCESS = 'User "%s" has been deleted successfully.';
export const USERS_DELETED_SUCCESS = 'Users deleted successfully.';
export const FIRSTNAME = 'firstname';
export const LASTNAME = 'lastname';
export const USER_NAME = 'username';
export const PHONE_NUMBER = 'phonenumber';
export const ROLES = 'roles';
export const ADD_USER = 'Add User';
export const ADD_USER_BUTTON = 'Add';
export const PHONENUMBER = 'Phone Number';
export const SELECT_ROLES = 'Select Roles';
export const ALL = 'All';
export const TOTAL_SELECTED = '%s Selected';
export const SAVE_CHANGES = 'Save Changes';
export const ADMIN = 'Admin';
export const ACTIONS = 'Actions';

// Aggregated View
export const SEARCH_PARTNERS_PLACEHOLDER = 'Search partners';
export const SYNC = 'Sync';
export const SYNC_PARTNER_WARNING =
    'You are about to sync all partner data. Are you sure?';
export const SYNC_MODAL_TITLE = 'Sync Partner';
export const SYNCED_SUCCESS = 'Partners have been synced successfully.';
export const INACTIVE_INTEGRATION = 'inactiveIntegration';
export const DRAFT_INTEGRATION = 'draftIntegration';
export const INITIALIZING_COLLECTOR = 'initializingCollector';
export const PENDING_ACTIVATION_COLLECTOR = 'pendingActivationCollector';
export const SEARCH_TENANTS_PLACEHOLDER = 'Search tenants';
export const LAST_SYNC = 'Last Sync';
export const COLON = ':';
export const MINUTES = 'minutes';

// Custom Settings
export const CUSTOM_SETTINGS_TITLE = 'Custom Settings';
export const AMP_DASHBOARDS = 'AMP Dashboards';
export const PARTNER_DASHLETS_ASQ_PARAGRAPH =
    'Here is a list of ASQs that will be displayed as default dashboards in the partner portal. Select the appropriate region from the top right drop-down.';
export const ADD_QUERY = 'Add query';
export const DELETE_QUERY = 'Delete query';
export const EDIT_QUERY = 'Edit query';
export const QUERY_TITLE = 'Title';
export const QUERY_VALUE = 'Query';
export const QUERY_CREATE_SUCCESS = 'Query has been created successfully.';
export const QUERY_UPDATE_SUCCESS = 'Query has been updated successfully.';
export const QUERY_DELETE_SUCCESS = 'Query has been deleted successfully.';
export const QUERY_DELETE_WARNING =
    'You are about to delete query "%s". Are you sure?';
export const QUERY = 'Query';
export const QUERIES = 'Queries';
export const NO_RESULT_REGIONS = 'No result found';
export const REGIONS_LABEL = 'Select Region';
export const MAX_DASHLET_LIMIT_REACHED = 'Maximum of 10 queries are supported.';

// Report Templates
export const REFRESH = 'Refresh';
export const UPLOAD_JSON = 'Upload JSON';
export const UPLOAD_FILE = 'Upload File';
export const INVALID_JSON = 'Please enter valid JSON.';
export const INVALID_JSON_FILE = 'Please upload valid JSON File.';
export const AMP_REPORT_TEMPLATES = 'AMP Report Templates';
export const SEARCH_REPORT_PLACEHOLDER = 'Search reports';
export const REPORT = 'Report';
export const REPORTS = 'Reports';
export const VIEW_JSON = 'View JSON';
export const DOWNLOAD_JSON = 'Download JSON';
export const IMPORT_REPORT = 'Import Report';
export const DELETE_REPORT = 'Delete Report';
export const DELETE_SINGLE_REPORT =
    'You are about to delete report template "%s". Are you sure?';
export const REPORT_UPDATE_SUCCESS = 'Report "%s" has been edited successfully.';
export const REPORT_DELETED_SUCCESS = 'Report deleted successfully.';
export const REPORT_CREATE_SUCCESS = 'Report "%s" has been added successfully.';
