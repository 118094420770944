import React, { useState, useMemo, useEffect } from 'react';

import {
    Button,
    TableNoData,
    Tooltip,
    WhitePlus
} from '@armis/armis-ui-library';
import { tooltipClasses } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DASHLETS_LIMIT, TOAST_ID } from 'src/constants/APIConstants';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    ADD_QUERY,
    MAX_DASHLET_LIMIT_REACHED,
    AMP_DASHBOARDS,
    PARTNER_DASHLETS_ASQ_PARAGRAPH,
    QUERIES,
    QUERY,
    QUERY_CREATE_SUCCESS,
    QUERY_DELETE_SUCCESS,
    QUERY_TITLE,
    QUERY_UPDATE_SUCCESS,
    QUERY_VALUE
} from 'src/constants/LabelText';
import { NO_DATA_TO_SHOW } from 'src/constants/TableConstants';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import {
    createDashletASQ,
    deleteASQ,
    editDashletASQ,
    getDashletsASQs,
    getRegions
} from 'src/services/api.service';
import {
    selectRegions,
    selectSelectedRegion,
    setRegions,
    setSelectedRegion
} from 'src/store/slices/regionSlice';
import { DashletAsqPayload } from 'src/types/APIPayloadTypes';
import { RegionResponseData } from 'src/types/APIResponseTypes';
import { ModalFieldType } from 'src/types/CommonTypes';

import { ASQItem } from './ASQItem';
import { initialData } from './constants';
import {
    ButtonContainer,
    StyledASQContainer,
    StyledSectionParagraph
} from './DashletsASQ.style';
import { ASQ, ASQActionType, DashletsASQProps } from './DashletsASQ.types';
import { QueryActionModal } from './QueryActionModal';

const DashletsASQComponent = ({
    setIsLoading,
    loadingConfig
}: DashletsASQProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [asqList, setASQList] = useState<ASQ[]>([]);
    const [actionModal, setActionModal] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);
    const [selectedASQ, setSelectedASQ] = useState<null | ASQ>(null);
    const [modalFields, setModalFields] = useState<ModalFieldType>(initialData);
    const [actionType, setActionType] = useState<ASQActionType>('add');
    const [msgToBeDisplayed, setmsgToBeDisplayed] = useState('');

    const dispatch = useDispatch();
    const selectedRegion = useSelector(selectSelectedRegion);
    const avlRegions = useSelector(selectRegions);

    const dashletLimitReached = asqList.length >= DASHLETS_LIMIT;
    const addQueryBtn = (
        <Button
            className="control has-inline-title svg-symbol-button transition theme-5 header-add-button"
            color="primary"
            disabled={avlRegions.length === 0 || dashletLimitReached}
            onClick={e => {
                e.currentTarget.blur();
                setActionModal(true);
            }}
            onPointerEnter={() => {
                if (dashletLimitReached) setShowTooltip(true);
            }}
            onPointerLeave={() => {
                if (dashletLimitReached) setShowTooltip(false);
            }}
            startIcon={
                <span>
                    <WhitePlus />
                </span>
            }
            sx={dashletLimitReached ? { cursor: 'not-allowed !important' } : {}}
            variant="contained"
        >
            <span>{ADD_QUERY}</span>
        </Button>
    );
    const topHeadingSection = useMemo(
        () => (
            <>
                <Header title={AMP_DASHBOARDS}>
                    <ProtectedAction
                        hasPermissions={[Privileges.create]}
                        resource={Resources.settings}
                    >
                        {dashletLimitReached ? (
                            <Tooltip
                                arrow
                                open={showTooltip}
                                sx={{
                                    [`& .${tooltipClasses.tooltip}`]: {
                                        right: '1px'
                                    }
                                }}
                                title={
                                    <div className="tooltip-arrow-text">
                                        {MAX_DASHLET_LIMIT_REACHED}
                                    </div>
                                }
                            >
                                {addQueryBtn}
                            </Tooltip>
                        ) : (
                            addQueryBtn
                        )}
                    </ProtectedAction>
                </Header>
                <StyledSectionParagraph>
                    {PARTNER_DASHLETS_ASQ_PARAGRAPH}
                </StyledSectionParagraph>
            </>
        ),
        [dashletLimitReached, avlRegions]
    );

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true);

                if (!selectedRegion) {
                    const {
                        data: { content: regions }
                    }: AxiosResponse<RegionResponseData> = await getRegions();
                    dispatch(setRegions(regions));
                } else {
                    const { data: dashlets }: AxiosResponse<ASQ[]> =
                        await getDashletsASQs(selectedRegion);

                    if (msgToBeDisplayed) {
                        showToast(
                            msgToBeDisplayed,
                            TOAST_TYPE.SUCCESS,
                            TOAST_ID
                        );
                        setmsgToBeDisplayed('');
                    }
                    setASQList(dashlets);
                }
            } catch (err: any) {
                displayErrorMessage(err);
                setASQList([]);
            } finally {
                setIsLoading(false);
            }
        };

        getData();
    }, [refreshPage, selectedRegion]);

    useEffect(
        () => () => {
            dispatch(setRegions([]));
            dispatch(setSelectedRegion(''));
        },
        []
    );

    const resetActionModal = () => {
        setActionModal(false);
        setModalFields(initialData);
        setActionType('add');
        setSelectedASQ(null);
    };

    const addDashletAsq = (data: DashletAsqPayload) => {
        setIsLoading(true);
        createDashletASQ(data)
            .then(() => {
                resetActionModal();
                setRefreshPage(prevValue => !prevValue);
                setmsgToBeDisplayed(QUERY_CREATE_SUCCESS);
            })
            .catch(err => {
                displayErrorMessage(err);
                setIsLoading(false);
            });
    };

    const updateDashletAsq = (data: DashletAsqPayload) => {
        setIsLoading(true);
        editDashletASQ(selectedASQ!.id, data)
            .then(() => {
                resetActionModal();
                setRefreshPage(prevValue => !prevValue);
                setmsgToBeDisplayed(QUERY_UPDATE_SUCCESS);
            })
            .catch(err => {
                displayErrorMessage(err);
                setIsLoading(false);
            });
    };

    const deleteDashletAsq = () => {
        setIsLoading(true);
        deleteASQ(selectedASQ!.id, selectedRegion)
            .then(() => {
                resetActionModal();
                setRefreshPage(prevValue => !prevValue);
                setmsgToBeDisplayed(QUERY_DELETE_SUCCESS);
            })
            .catch(err => {
                displayErrorMessage(err);
                setIsLoading(false);
            });
    };

    return (
        <>
            {topHeadingSection}
            <div className="control table" style={{ marginTop: '10px' }}>
                {actionModal && (
                    <QueryActionModal
                        actionType={actionType}
                        asq={selectedASQ!}
                        modalFields={modalFields}
                        onCancel={() => resetActionModal()}
                        onSubmit={(dataObject: DashletAsqPayload) => {
                            if (actionType === 'add') {
                                addDashletAsq(dataObject);
                            } else if (actionType === 'edit') {
                                updateDashletAsq(dataObject);
                            } else if (actionType === 'delete') {
                                deleteDashletAsq();
                            }
                        }}
                        setModalFields={setModalFields}
                        submitDisable={loadingConfig.loading}
                    />
                )}
                <ButtonContainer>
                    <div className="queries-count">
                        {asqList.length}{' '}
                        {asqList.length === 1 ? QUERY : QUERIES}
                    </div>
                </ButtonContainer>
                <StyledASQContainer>
                    {asqList.length === 0 && !loadingConfig.loading && (
                        <TableNoData content={NO_DATA_TO_SHOW} />
                    )}
                    {asqList.map(({ asq, id, title }, index) => (
                        <ASQItem
                            key={id}
                            asq={asq}
                            onClickAction={(type: ASQActionType) => {
                                setActionType(type);
                                setSelectedASQ(asqList[index]);
                                setModalFields(prevValue => ({
                                    ...prevValue,
                                    [QUERY_TITLE]: {
                                        ...prevValue[QUERY_TITLE],
                                        value: title
                                    },
                                    [QUERY_VALUE]: {
                                        ...prevValue[QUERY_VALUE],
                                        value: asq
                                    }
                                }));
                                setActionModal(true);
                            }}
                            title={title}
                        />
                    ))}
                </StyledASQContainer>
            </div>
        </>
    );
};

export const DashletsASQ = IsLoadingHOC(DashletsASQComponent);
